import React from 'react';
import Layout from '../../components/Layout';
import ProjectHeader from '../../components/ProjectHeader';
import HellonautIntroIntro from '../../components/Hellonaut/HellonautIntro'
import HellonautContent from '../../components/Hellonaut/HellonautContent'
import Footer from '../../components/Footer';

const IndexPage = () => (
  <Layout>
    <ProjectHeader />
    <HellonautIntroIntro />
    <HellonautContent />
    <Footer />
  </Layout>
);

export default IndexPage;

import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Box, Flex, Image } from 'rebass';
import Fade from 'react-reveal/Fade';
import ReactMarkdown from 'react-markdown';
import { ProjectContentCard } from '../ProjectContentCard';
import Section from '../Section';
import markdownRenderer from '../MarkdownRenderer';
import SeeProjects from '../../sections/SeeProjects';
import {
  ProjectImageLeft,
  ProjectImageRight,
  ProjectImageTransparent,
} from '../ProjectImage';
import ProjectTitle from '../ProjectTitle';

const HellonautContent = () => (
  <Section.Container id="Hellonaut Content">
    <StaticQuery
      query={graphql`
        query HellonautContent {
          contentfulHellonaut {
            abstract {
              abstract
            }
            introduction {
              introduction
            }
            challenge {
              challenge
            }
            solution {
              solution
            }
            benefit {
              benefit
            }
            technology {
              technology
            }
            history {
              history
            }
            result {
              result
            }
            contentPicturesTransparent {
              id
              title
              file {
                url
                fileName
              }
            }
            contentPicturesThesis {
              id
              title
              file {
                url
                fileName
              }
            }
            contentPicturesHellonaut {
              id
              title
              file {
                url
                fileName
              }
            }
            videoSection
          }
        }
      `}
      render={({ contentfulHellonaut }) => {
        const imgScreens1 = contentfulHellonaut.contentPicturesTransparent.filter(
          object => object.title === 'Screens 1',
        );
        const imgScreens2 = contentfulHellonaut.contentPicturesTransparent.filter(
          object => object.title === 'Helllonaut Screens',
        );
        const imgPlanets = contentfulHellonaut.contentPicturesTransparent.filter(
          object => object.title === 'planets',
        );

        const imgStakeholder = contentfulHellonaut.contentPicturesThesis.filter(
          object => object.title === 'Stakeholder',
        );
        const imgTNS = contentfulHellonaut.contentPicturesThesis.filter(
          object => object.title === 'Stakeholder TNS-01',
        );
        const imgBrandArchitecture = contentfulHellonaut.contentPicturesThesis.filter(
          object => object.title === 'Brand Architecture',
        );
        const imgBusinessModel = contentfulHellonaut.contentPicturesThesis.filter(
          object => object.title === 'Business Model',
        );
        const imgJourney = contentfulHellonaut.contentPicturesThesis.filter(
          object => object.title === 'BusinessJourney',
        );

        const imgTrain = contentfulHellonaut.contentPicturesHellonaut.filter(
          object => object.title === 'Hellonaut train',
        );
        const imgGranada = contentfulHellonaut.contentPicturesHellonaut.filter(
          object => object.title === 'Granada',
        );
        const imgBanner = contentfulHellonaut.contentPicturesHellonaut.filter(
          object => object.title === 'Hellonaut Banner 2',
        );

        return (
          <Fragment>
            <ProjectContentCard>
              <Flex justifyContent="top" flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Abstract</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.abstract.abstract}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgScreens1.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTrain.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgGranada.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 3]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Introduction</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.introduction.introduction}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Box width={[1, 1, 1 / 3]} />

                  <Box width={[1, 1, 2 / 3]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Challenge</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulHellonaut.challenge.challenge}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgStakeholder.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgTNS.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgBrandArchitecture.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgBusinessModel.map(img => (
                      <ProjectImageLeft src={img.file.url} key={img.id} />
                    ))}
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Solution</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.solution.solution}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Benefit</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.benefit.benefit}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  <Box width={[1, 1, 1]} py={[15, 40, 0]}>
                    {imgJourney.map(img => (
                      <ProjectImageRight src={img.file.url} key={img.id} />
                    ))}
                  </Box>

                  <Box width={[1, 1, 1]} px={[2, 3, 4]}>
                    <Fade bottom>
                      <ProjectTitle>Technology</ProjectTitle>
                      <ReactMarkdown
                        source={contentfulHellonaut.technology.technology}
                        renderers={markdownRenderer}
                      />
                    </Fade>
                  </Box>
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>History</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.history.history}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgPlanets.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]}>
                  {imgScreens2.map(img => (
                    <ProjectImageTransparent src={img.file.url} key={img.id} />
                  ))}
                </Box>

                <Box width={[1, 1, 1 / 2]} px={[2, 3, 4]}>
                  <Fade bottom>
                    <ProjectTitle>Result</ProjectTitle>
                    <ReactMarkdown
                      source={contentfulHellonaut.result.result}
                      renderers={markdownRenderer}
                    />
                  </Fade>
                </Box>
              </Flex>
            </ProjectContentCard>

            <ProjectContentCard style={{ background: '#000000' }}>
              <Box width={[1, 1, 1]} px={[0, 30, 40]}>
                <iframe
                  title="Hellonaut Explained"
                  width="100%"
                  height="400"
                  src={contentfulHellonaut.videoSection}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </Box>
            </ProjectContentCard>

            <ProjectContentCard
              style={{ paddingTop: '0px', paddingBottom: '0px' }}
            >
              {imgBanner.map(img => (
                <Image
                  src={img.file.url}
                  key={img.id}
                  style={{ borderRadius: 25 }}
                  my={[20, 0 , 0]}
                />
              ))}
            </ProjectContentCard>

            <SeeProjects />
          </Fragment>
        );
      }}
    />
  </Section.Container>
);

export default HellonautContent;

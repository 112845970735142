import React, { Fragment } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image, Box } from 'rebass';
import { SectionLink } from 'react-scroll-section';
import styled from 'styled-components';
import MouseIcon from '../MouseIcon';
import { Hero, Subheading } from '../ProjectHeading';

const Banner = styled(Image)`
  margin-bottom: -80px;
`;

const HeaderBox = styled(Box)`
  position: absolute;
  font-style: italic;
  text-align: right;
  margin-left: 40%;


  transform: rotate(-10deg);

  @media only screen and (max-width: 700px) {
    margin: 'auto';
  }
`;

const HellonautIntro = () => (
  <StaticQuery
    query={graphql`
      query HellonautIntro {
        contentfulHellonaut {
          title
          subtitle
          banner {
            file {
              url
            }
          }
        }
      }
    `}
    render={({ contentfulHellonaut }) => {
      return (
        <Fragment>
          <HeaderBox pt={[140, 100, 200]} mr={[60]}>
            <Hero as="h1" color="totalDark" fontSize={[5, 6, 8]} mb={[2, 3, 4]}>
              {contentfulHellonaut.title}
            </Hero>

            <Subheading
              as="h2"
              color="totalDark"
              fontSize={[4, 5, 6]}
              mb={[3, 5]}
            >
              {contentfulHellonaut.subtitle}
            </Subheading>
          </HeaderBox>

          <div>
            <Banner
              mt={[50, 50, 0]}
              src={contentfulHellonaut.banner.file.url}
              width="97%"
              alt="Distant Grounds Application with Processing"
            />
          </div>

          <SectionLink section="Hellonaut Content">
            {({ onClick }) => <MouseIcon onClick={onClick} />}
          </SectionLink>
        </Fragment>
      );
    }}
  />
);

export default HellonautIntro;
